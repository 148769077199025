(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/infobar/views/infobar_partial.js') >= 0) return;  svs.modules.push('/components/ui/infobar/views/infobar_partial.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.ui=_svs.ui||{};
_svs.ui.infobar=_svs.ui.infobar||{};
_svs.ui.infobar.partials=_svs.ui.infobar.partials||{};
svs.ui.infobar.partials.infobar = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "infobar-inverted";
},"3":function(container,depth0,helpers,partials,data) {
    return "infobar-default";
},"5":function(container,depth0,helpers,partials,data) {
    return " infobar-no-bottom-margin";
},"7":function(container,depth0,helpers,partials,data) {
    return " infobar-large";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":200},"end":{"line":1,"column":209}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row infobar "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inverted") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":29},"end":{"line":1,"column":91}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noBottomMargin") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":91},"end":{"line":1,"column":145}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"largeInfobar") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":145},"end":{"line":1,"column":186}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":186},"end":{"line":1,"column":216}}})) != null ? stack1 : "")
    + "\">\n"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":11}}}) : helper)))
    + "\n</div>\n";
},"useData":true});
Handlebars.partials['ui-infobar-infobar'] = svs.ui.infobar.partials.infobar;
})(svs, Handlebars);


 })(window);